<template>
  <div class="warp" v-if="itemList">
    <div class="top" ref="topcolor">
      <h1>固废码</h1>
    </div>
    <div class="main">
      <van-field label="企业名称：" label-width="120" v-model="itemList.comName" disabled type="textarea" autosize rows="1" />
      <van-field label="负责人：" label-width="120" v-model="itemList.legalName" disabled type="textarea" autosize rows="1" />
      <van-field label="岗位培训：" label-width="120" v-model="itemList.instructorIsPro" disabled type="textarea" autosize rows="1" />
      <van-field label="焚烧类去向" label-width="120" v-model="itemList.receiveComName1" disabled type="textarea" autosize rows="1" />
      <van-field label="收运单位：" label-width="120" v-model="itemList.trafficComName" disabled type="textarea" autosize rows="1" />
      <van-field label="可利用去向" label-width="120" v-model="itemList.receiveComName3" disabled type="textarea" autosize  rows="1"/>
      <van-field label="回收单位" label-width="120" v-model="itemList.receiveComName3" disabled type="textarea" autosize  rows="1"/>
      <van-field label="固废指导员：" label-width="120" v-model="itemList.instructor" disabled type="textarea" autosize rows="1" />
      <div class="btn">
        <van-button round block type="info" @click="zfClick">走访</van-button>
      </div>
      <div class="time">{{'更新时间：' + (itemList.createTime ? conversionTime(itemList.createTime) : '无')}}</div>
      <p></p>
    </div>
  </div>
</template>

<script>
import { companyInfoqym } from '@/api/notice.js'

export default {
  data () {
    return {
      itemList: {}
    }
  },
  created() {
    this.companyInfoqym()
  },
  methods: {
    zfClick() {
      let authorize = 'https://gy-wx.nbhhl.com/prod-api'
      let index = `Visitrecord?uscc=${this.itemList.uscc}`
      let url = authorize + "/wechat/portal/authorize?returnUrl=" + authorize + "/wechat/login?index=" + index
      window.location.href = url
    },
    // 计算两个时间差 dateBegin 开始时间
    timeFn(dateBegin, dateEnd, registerTime) {
      var dataTime = dateEnd * 1000 - registerTime * 1000 // 当前时间减去注册时间
      var dayTime = Math.floor(dataTime / (24 * 3600 * 1000));//计算出相差天数
      //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
      var dateDiff = dateEnd * 1000 - dateBegin * 1000;//时间差的毫秒数时间戳
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000));//计算出相差天数
      var leave1=dateDiff%(24*3600*1000)  //计算天数后剩余的毫秒数
      var hours=Math.floor(leave1/(3600*1000))//计算出小时数
      //计算相差分钟数
      var leave2=leave1%(3600*1000)  //计算小时数后剩余的毫秒数
      var minutes=Math.floor(leave2/(60*1000))//计算相差分钟数
      //计算相差秒数
      var leave3=leave2%(60*1000)   //计算分钟数后剩余的毫秒数
      var seconds=Math.round(leave3/1000)
      var leave4=leave3%(60*1000)   //计算分钟数后剩余的毫秒数
      var minseconds=Math.round(leave4/1000)
      // var timeFn = "耗时："+dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒"+minseconds+"毫秒";
      // var timeFn = dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒"
      if (dayTime <= 60) {
        this.$refs.topcolor.style.backgroundColor = '#48cd89'
      } else {
        if (dayDiff <= 60) {
          this.$refs.topcolor.style.backgroundColor = '#48cd89'
        } else if (dayDiff > 60 && dayDiff <= 120) {
          this.$refs.topcolor.style.backgroundColor = '#ffed8e'
          this.$refs.topcolor.style.color = '#333'
        } else if (dayDiff > 120) {
          this.$refs.topcolor.style.backgroundColor = '#e11919'
        }
      }
    },
    companyInfoqym() {
      companyInfoqym({uscc: this.$route.query.uscc}).then(res => {
        if (res.data.code === 200) {
          this.itemList = res.data.data
          if (!this.itemList.comName) {
            this.itemList.comName = '-'
          }
          if (!this.itemList.legalName) {
            this.itemList.legalName = '-'
          }
          if (!this.itemList.receiveComName) {
            this.itemList.receiveComName = '-'
          }
          if (!this.itemList.trafficComName) {
            this.itemList.trafficComName = '-'
          }
          if (!this.itemList.instructor) {
            this.itemList.instructor = '-'
          }
          if (this.itemList.instructorIsPro == 0) {
            this.itemList.instructorIsPro = '未参加'
          } else if (this.itemList.instructorIsPro == 1) {
            this.itemList.instructorIsPro = '已参加'
          } else {
            this.itemList.instructorIsPro = '-'
          }
          if (!this.itemList.receiveComName1) {
            this.itemList.receiveComName1 = '-'
          }
          if (!this.itemList.receiveComName3) {
            this.itemList.receiveComName3 = '-'
          }
          const timestamp = parseInt((new Date()).getTime() / 1000)
          const createTime = this.itemList.createTime
          const registerTime = this.itemList.registerTime
          this.timeFn(createTime, timestamp, registerTime)
        }
      })
    },
  }
}

</script>
<style lang="scss" scoped>
.warp {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    background-color: #fff;
    margin-bottom: 10px;
    color: #fff;
    padding: 30px;
    box-sizing: border-box;
    h2 {
      text-align: center;
    }
  }
  .main {
    .btn {
      padding: 30px;
      box-sizing: border-box;
    }
    .time {
      font-size: 18px;
      text-align: center;
    }
  }
}
::v-deep .van-field--disabled .van-field__label {
  color: #333;
}
::v-deep .van-cell {
  font-size: 16px;
}
::v-deep .van-field__control:disabled {
  color: #333;
  -webkit-text-fill-color: #333;
}
::v-deep .van-button--round {
  border-radius: 3px;
}
::v-deep .van-button--info {
  background-color: #0087f0;
  border: 1px solid #0087f0;
}
</style>
